import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import { Helmet } from "react-helmet";

type PropTypes = {
  title: string;
};

const PageHelmet: React.FC<PropsWithChildren<PropTypes>> = ({
  title,
  children,
}) => {
  // const HOST = process.env.GATSBY_HOST;

  useEffect(() => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      // @ts-ignore
      dataLayer.push(arguments);
    }

    // @ts-ignore
    gtag("js", new Date());

    // @ts-ignore
    gtag("config", "G-DGEQP9C2RK");
  }, []);

  useEffect(() => {
    const host = window.location.href;
    fetch(`https://db.daussho.my.id/ping.json?from=${host}`);
  }, []);

  return (
    <Helmet>
      <title>{title}</title>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-DGEQP9C2RK"
      />
      <script async src="https://analytics.umami.is/script.js" data-website-id="da11685b-6458-44c2-8f0b-3840a88e6a0c"></script>
      {children}
    </Helmet>
  );
};

export default PageHelmet;
